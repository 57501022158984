.mobileMenu-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    .mobileMenu {
        position: fixed;
        top: 75px;
        left: 0;
        z-index: 100;
        width: 100vw;
        padding: 0px 0px 45px 0px;
        background: #000000;
        border-top: 1px solid #2A2B3A;
        backdrop-filter: blur(2px);
        .mobileMenu__list {
            margin-bottom: 73px;
            .mobileMenu__item {
                height: 50px;
                padding: 14px 21px;
                &:active {
                    background-color: #2A2B3A;
                }
                a {
                    font-size: 16px;
                    line-height: 135%;
                    letter-spacing: -0.01em;
                    color: #FFFFFF;
                    text-decoration: none;
                }
            }
        }
        .mobileMenu__folow-list {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            padding: 0 21px;
            .mobileMenu__folow-item {
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                &:hover .footer__folow-img {
                    filter: brightness(0) saturate(100%) invert(33%) sepia(74%) saturate(4015%) hue-rotate(251deg) brightness(101%) contrast(101%);
                }
            }
        }
    }
}