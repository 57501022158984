//Imports
@import url(./reset.scss);

//Defolt settings
* {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

@font-face {
    font-family: poppinsRegular;
    src: url(../assets/fonts/Poppins-Regular.ttf);
}
@font-face {
    font-family: poppinsMedium;
    src: url(../assets/fonts/Poppins-Medium.ttf);
}
@font-face {
    font-family: poppinsBold;
    src: url(../assets/fonts/Poppins-Bold.ttf);
}
@font-face {
    font-family: code;
    src: url(../assets/fonts/SpaceMono-Regular.ttf);
}

img {
	max-width: 100%;
	-o-object-fit: cover;
	object-fit: cover;
}

body {
	background-size: cover;
	font-family: 'poppinsRegular', sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 135%;
	letter-spacing: -0.01em;
	color: #9E9EAC;
	background: /* url('../assets/img/sectionBg.webp') */ #000 no-repeat center top;
}
html {
	scroll-behavior: smooth;
}

h1,
.title1 {
	font-family: 'poppinsBold', sans-serif;
	color: #fff;
	font-weight: 700;
	font-size: 60px;
	line-height: 100%;
}

h2,
.title2 {
	color: #ffffff;
	font-weight: 400;
	font-size: 40px;
	line-height: 100px;
}

h3,
.title3 {
	font-weight: 400;
	font-size: 32px;
	line-height: 48px;
}

h4,
.title4 {
	font-weight: 400;
	font-size: 24px;
	line-height: 135%;
	letter-spacing: -0.01em;
}

h5,
.title5 {
	font-weight: 400;
	font-size: 20px;
	line-height: 135%;
	letter-spacing: -0.01em;
}

.btn-contact {
	width: 312px;
	height: 50px;
	background-color: #2A2B3A;
	border: 1px solid #2A2B3A;
	box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
	border-radius: 8px;
	padding: 14px 16px;
	text-align: center;
	font-weight: 400;
	font-family: 'poppinsRegular', sans-serif;
	font-size: 16px;
	line-height: 135%;
	letter-spacing: -0.01em;
	color: #fff;
	cursor: pointer;
	transition: 0.2s ease-in;
	border: none;
	outline: none;
	&:hover {
		background-color: #9747FF;
	}
}

.container {
	max-width: 1125px;
	margin: 0 auto;
	padding: 0 20px;
}

@media only screen and (max-width: 1024px) {
	.container {
		max-width: 920px;
	}
}
@media only screen and (max-width: 1024px) {
	.container {
		max-width: 920px;
	}
}
@media only screen and (max-width: 768px) {
	.container {
		max-width: 684px;
	}
}
@media only screen and (max-width: 360px) {
	.container {
		max-width: 320px;
	}
}

.lightEffectWrapper {
	position: relative;
	.lightEffectWrapper__card {
		position: relative;
		.light{
            top: 0;
            left: 0;
            opacity: 0;
            width: 100%;
            height: 100%;
            display: block;
            position: absolute;
            transition: opacity .3s;
            background: radial-gradient(circle at 52.8095px 0px, rgb(255, 255, 255), transparent);
			border-radius: 20px;
        }
        &:hover{
            .light{
                opacity: 0.4;
            }
        }
	}
	span {
        width: 50%;
        height: 50%;
        position: absolute;
        z-index: 1;
        &:first-child, &:nth-child(2) {
            top: 0;
        }
        &:first-child, &:nth-child(3) {
            left: 0;
        }
        &:nth-child(2), &:nth-child(4) {
            right: 0;
        }
        &:nth-child(3), &:nth-child(4) {
            bottom: 0;
        }
        &:first-child:hover ~ .lightEffectWrapper__card {
            transform: perspective(600px) rotateX(10deg) rotateY(-10deg);
            .light{
                opacity: 0.4;
            }
        }
        &:nth-child(2):hover ~ .lightEffectWrapper__card {
            transform: perspective(600px) rotateX(10deg) rotateY(10deg);
            .light{
                opacity: 0.4;
            }
        }
        &:nth-child(3):hover ~ .lightEffectWrapper__card {
            transform: perspective(600px) rotateX(-10deg) rotateY(-10deg);
            .light{
                opacity: 0.4;
            }
        }
        &:nth-child(4):hover ~ .lightEffectWrapper__card {
            transform: perspective(600px) rotateX(-10deg) rotateY(10deg);
            .light{
                opacity: 0.4;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
	.btn-contact {
		width: 270px;
	}
}

@media only screen and (max-width:500px) {
	h2 {
		font-weight: 400;
		font-size: 24px;
		line-height: 50px;
	}
	body {
		background-size: auto;
	}
	.lightEffectWrapper {
		span, .lightEffectWrapper__card .light {
			display: none;
		}
	}
}

@media only screen and (max-width:400px) {
	.btn-contact {
		width: 270px;
	}
}