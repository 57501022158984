.development {
    .development__title {
        max-width: 1180px;
        padding: 0 10px;
        margin: 0 auto;
        margin-bottom: 54px;
    }
    .development__code {
        .development__blocks {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 26px;
            padding: 0 10px;
            .development__blocks-activityAndDevelopers {
                transform: translateY(110px);
                .development__blocks-activity {
                    margin-bottom: 30px;
                    .development__keyboard-info--planshet {
                        display: none;
                    }
                }
            }
            .development__blocks-developers {
                background: #161B22;
                border: 1px solid #2A2B3A;
                border-radius: 12px;
                padding: 11px 13px 20px 11px;
                width: 226px;
                .development__developers-head {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .development__developers-title {
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 18px;
                        color: #FFFFFF;
                    }
                    .development__developers-all {
                        font-size: 8.77917px;
                        line-height: 13px;
                        color: #9747FF;
                    }
                }
                .development__developers-list {
                    .development__developers-item {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 10px 0;
                        border-top: 0.627083px solid #383838;
                        &:first-child {
                            border-top: none;
                        }
                        .development__developers-main-inf {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            .development__developers-avatar {
                                width: 26px;
                                height: 26px;
                                margin-right: 9px;
                            }
                            .transfer-from {
                                border-radius: 50%;
                            }
                            .development__developers-inf {
                                .development__developers-transfers {
                                    margin-bottom: 4px;
                                    font-size: 7.525px;
                                    line-height: 11px;
                                    letter-spacing: -0.02em;
                                    color: #9E9EAC;
                                }
                                .development__developers-name {
                                    font-weight: 500;
                                    font-size: 8.77917px;
                                    line-height: 13px;
                                    letter-spacing: -0.02em;
                                    color: #FFFFFF;
                                }
                            }
                        }
                        .development__developers-position, .development__developers-mooney {
                            font-weight: 500;
                            font-size: 8.77917px;
                            line-height: 13px;
                            text-align: right;
                            letter-spacing: -0.02em;
                            color: #9747FF;
                        }
                        .development__developers-mooney {
                            color: #F015B0;
                        }
                    }
                }
            }
        }
        }
    .development__keyboard {
        display: flex;
        justify-content: start;
        align-items: center;
        .development__keyboard-info {
            margin-left: 23px;
            .development__keyboard-text {
                max-width: 466px;
                text-align: start;
                margin-bottom: 27px;
                color: #E0E0E2;
            }
        }
    }
}


.development__blocks-overview {
    width: 307px;
}

@media only screen and (max-width: 1700px) {
    .development {
        .development__keyboard {
            align-items: end;
        }
    }
}

@media only screen and (max-width: 1200px) {
    .development {
        .development__title {
            padding: 0 20px;
            margin-bottom: 30px;
        }
        .development__keyboard {
            img {
                display: none;
            }
            .development__keyboard-info {
                .development__keyboard-text {
                    max-width: 959px;
                    margin-bottom: 49px;
                }
            }
        }
        .development__code {
            .development__blocks-overview {
                display: none;
            }
            .development__blocks {
                padding: 0 20px;
                margin: 0 auto;
                margin-bottom: 65px;
                .development__blocks-activityAndDevelopers {
                    transform: translateY(0);
                    .development__developers-head {
                        margin-bottom: 10px;
                    }
                }
                .development__blocks-developers {
                    .development__developers-list {
                        .development__developers-item {
                            padding: 8px 0;
                        }
                    }
                }
            }
        }
        .development__blocks-code {
            max-height: 470px;
            margin: 0 20px 0 0;
        }
    }
}

@media only screen and (max-width: 768px) {
    .development {
        .development__title {
            max-width: 767px;
            margin-bottom: 35px;
        }
        .development__code {
            .development__blocks-overview {
                display: none;
            }
            .development__blocks {
                max-width: 767px;
                padding: 0 20px;
                margin: 0 auto;
                margin-bottom: 65px;
                flex-direction: column;
                align-items: start;
                .development__blocks-developers {
                    display: none;
                }
                .development__blocks-activityAndDevelopers {
                    .development__blocks-activity {
                        display: flex;
                        justify-content: start;
                        align-items: end;
                        margin-bottom: 0;
                        .development__blocks-activity-lottie {
                            margin-right: 21px;
                            div {
                                width: 225px;
                                height: 200px;
                            }
                        }
                        .development__keyboard-info--planshet {
                            display: block;
                            .development__keyboard-text {
                                margin-bottom: 30px;
                            }
                        }
                    }
                }
            }
            .development__keyboard-info {
                display: none;
            }
        }
        .development__blocks-code {
            max-height: 470px;
            margin: 0;
            margin-bottom: 30px;
        }
    }
}

@media only screen and (max-width: 550px) {
    .development {
        margin-bottom: 50px;
        .development__title {
            margin-bottom: 30px;
        }
        .development__code {
            overflow: hidden;
            .development__blocks {
                overflow: hidden;
                margin-bottom: 0px;
                .development__blocks-activityAndDevelopers {
                    .development__blocks-activity-lottie {
                        display: none;
                    }
                    .development__blocks-activity {
                        .development__keyboard-info--planshet {
                            .development__contact {
                                width: 100%;
                                display: flex;
                                justify-content: center;
                            }
                        }
                    }
                }
                .development__blocks-developers {
                    display: none;
                }
            }
            }
        .development__keyboard {
            display: flex;
            justify-content: start;
            align-items: center;
            max-width: 360px;
            padding: 0 20px;
            margin: 0 auto;
            img {
                display: none;
            }
            .development__keyboard-info {
                margin-left: 0px;
                .development__keyboard-text {
                    max-width: 320px;
                    text-align: start;
                    margin-bottom: 27px;
                    color: #9E9EAC;
                }
            }
        }
        .development__blocks-overview {
            display: none;
        }
    }
    .code__text {
        overflow: hidden;
        position: relative;
        padding: 14px 0;
    }
}