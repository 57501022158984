@mixin white-gradient {
	background: linear-gradient(to right,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
}

@keyframes arrow {
    0% { transform: translateY(0); }
    100% { transform: translateY(10px); }
}

body {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.main {
    z-index: 3;
    .main__scroll-text {
        width: 100%;
        text-align: center; 
        margin-right: 15px;
        margin-bottom: -17px;
        margin-bottom: 28px;
    }
    .main__scroll-icon {
        font-size: 19px;
        margin-left: 10px;
        animation: 0.5s cubic-bezier(.51,.92,.24,1.15) 1s infinite alternate arrow;
    }
}

.swiper {
    .marquee {
        min-width: auto;
    }
}
.swiper-design {
    gap: 30px;
    .marquee {
        gap: 30px;
        min-width: auto;
    }
}
.body-scroll-hidden {
    overflow: hidden;
}

@media only screen and (max-width: 500px) {
    .main__scroll-text {
        display: none;
    }
}