.seo__card-head, .seo__card-head-icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
        letter-spacing: -0.01em;
        color: #9E9EAC;
    }
    .seo__card-head-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .seo__card-head-circle {
            width: 14px;
            height: 14px;
            border-radius: 50%;
            display: block;
            margin-right: 12px;
        }
        .seo__card-head-circle--purple{
            background: #9747FF;
        }
        .seo__card-head-circle--pink {
            background: #F015B0;
        }
    }
    .seo__card-head-list {
        width: 100%;
        display: flex;
        justify-content: start;
        align-items: center;
        .seo__card-item {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 48px;
            width: 18px;
            height: 20px;
            cursor: pointer;
            &:hover .seo__card-list-img {
                filter: brightness(0) saturate(100%) invert(33%) sepia(74%) saturate(4015%) hue-rotate(251deg) brightness(101%) contrast(101%);
            }
        }
    }
    .seo__card-item-plus {
        font-size: 23px;
        margin-left: 14px;
        cursor: pointer;
    }
}

.seo__card-body {
    .seo__card-body-content {
        display: flex;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        margin-left: 18px;
        align-items: start;
        .seo__card-body-subtitle {
            background: #3C3D4C;
            border-radius: 50px;
            height: 17px;
        }
        .seo__card-body-subtitle {
            width: 70%;
            margin-bottom: 24px;
        }
        .seo__card-body-btn {
            background: #3C3D4C;
            border-radius: 8px;
            color: #9E9EAC;
            width: 100%;
            padding: 7px 39px;
        }
    }
}

.seo__card-head-icons {
    padding-left: 30px;
    padding-right: 29px;
}

@media only screen and (max-width: 1060px) {
    .seo__card-head, .seo__card-head-icons {
        padding: 12px 26px 12px 26px;
        height: 70px;
        .seo__card-head-list {
            .seo__card-item {
                margin-right: 35px;
                width: 17px;
                height: 19px;
            }
        }
        .seo__card-item-plus {
            margin-left: 4px;
        }
    }
    .seo__card-body {
        .seo__card-body-content {
            justify-content: center;
            .seo__card-body-btn {
                padding: 7px 15px;
            }
        }
    }
}

@media only screen and (max-width: 1060px) {
    .seo__card-head, .seo__card-head-icons {
        .seo__card-head-list {
            .seo__card-item {
                margin-right: 20px;
                width: 15px;
                height: 17px;
            }
        }
        .seo__card-item-plus {
            margin-left: 2px;
        }
    }
    .seo__card-body {
        .seo__card-body-content {
            justify-content: center;
            .seo__card-body-btn {
                padding: 7px 15px;
            }
        }
    }
}

@media only screen and (max-width: 635px) {
    .seo__card-head, .seo__card-head-icons {
        .seo__card-head-list {
            .seo__card-item {
                margin-right: 47px;
            }
        }
    }
    .seo__card-body-full {
        .seo__card-body-imgElem {
            height: 105px;
        }
    }
    .seo__card-body--big-img {
        div {
            svg {
                width: 270px !important;
            }
        }
    }
}