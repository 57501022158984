@keyframes showCard {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.seo {
    max-width: 1180px;
    padding: 0 10px;
    margin: 0 auto;
    margin-bottom: 100px;
    .seo__title {
        margin-bottom: 54px;
    }
    .seo__titles--mobile {
        display: none;
        width: 100%;
    }
    .seo__navigate {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 46px;
        .seo__titles {
            display: flex;
            justify-content: start;
            align-items: center;
            padding: 8px;
            background: #000000;
            border: 1px solid #2A2B3A;
            border-radius: 8px;
            .seo__navigate-btn, .seo__navigate-btn--active {
                background: transparent;
                border: 1px solid transparent;
                border-radius: 4px;
                padding: 12px 16px;
                text-align: center;
                font-size: 14px;
                line-height: 20px;
                color: #9E9EAC;
                cursor: pointer;
            }
            .seo__navigate-btn + .seo__navigate-btn, .seo__navigate-btn--active + .seo__navigate-btn--active {
                margin-left: 16px;
            }
            .seo__navigate-btn--active {
                background: #2A2B3A;
                border: 1px solid #2A2B3A;
                color: #FFFFFF;
            }
        }
        .seo__icons {
            button {
                background: #000000;
                border: 1px solid #2A2B3A;
                box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
                border-radius: 8px;
                padding: 13px 19px;
                cursor: pointer;
                .seo__icons-img {
                    width: 18px;
                }
            }
            button + button {
                margin-left: 8px;
            }
        }
    }
    .seo__row {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 31px;
        margin-bottom: 50px;
        .seo__card {
            padding: 12px 0 24px 0;
            background: rgba(1, 3, 20, 0.4);
            backdrop-filter: blur(5px);
            border: 1px solid #2A2B3A;
            border-radius: 10px;
            height: 426px;
            transition: 0.3s all;
            animation: showCard 0.3s linear;
            .seo__card-head {
                height: 70px;
                padding: 31px 26px;
                border-bottom: 1px solid #3C3D4C;
                .seo__card-head-title {
                    color: #9E9EAC;
                }
            }
            .seo__card-body {
                border-top: 1px solid #2A2B3A;
                border-bottom: 1px solid #2A2B3A;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
            }
            .seo__card-subtitle {
                color: #FFFFFF;
            }
            .seo__card-footer {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                padding: 20px 0 24px 0;
            }
            .seo__card-text {
                margin-bottom: 20px;
                text-align: center;
            }
            .seo-btn {
                margin: 0 auto;
                display: flex;
                justify-content: center;
                align-content: center;
                padding: 0 16px;
                width: 100%;
                button {
                    width: 100%;
                }
            }
        }
    }
}
.seo__pagination {
    display: flex;
    justify-content: start;
    align-items: center;
    .seo__pagination-wrap {
        border: 1px solid #2A2B3A;
        border-radius: 50%;
        width: 65px;
        height: 65px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 30px;
        cursor: pointer;
        &:hover {
            background: #2A2B3A;
            border: 1px solid #2A2B3A;
            backdrop-filter: blur(4px);
        }
        &:hover .seo__pagination-icon {
            color: #fff;
        }
        .seo__pagination-icon {
            color: #9E9EAC;
            font-size: 25px;
        }
    }
}

@media only screen and (max-width: 1060px) {
    .seo {
        max-width: 1018px;
        padding: 0 20px;
        margin-bottom: 65px;
        .seo__title {
            margin-bottom: 29px;
        }
        .seo__icons {
            display: none;
        }
        .seo__row {
            margin-bottom: 65px;
            .seo__card {
                height: 410px;
                padding: 0;
                .seo__card-header {
                    padding: 5px 16px;
                    height: 70px;
                }
                .seo__card-statistic {
                    padding: 84px 16px 17px 16px;
                }
                .seo__card-footer {
                    height: 158px;
                }
            }
            .seo__card-text {
                margin: 0 16px;
            }
            .seo__card-text-canada {
                padding: 0 38px;
            }
            .seo__card-text-uk {
                padding: 0 32px;
            }
        }
    }
    .seo__pagination {
        .seo__pagination-wrap {
            width: 56px;
            height: 56px;
        }
    }
}

@media only screen and (max-width: 960px) {
    .seo {
        margin-bottom: 70px;
        .seo__row {
            margin-bottom: 50px;
            column-gap: 20px;
            .seo__card {
                height: 365px;
                .seo__card-header {
                    padding: 5px 19px;
                    height: 70px;
                    .seo__card-volume-btn {
                        display: none;
                    }
                }
                .seo__card-body {
                    height: 135px;
                    .seo__card-body-img {
                        height: 135px;
                    }
                }
                .seo__card-statistic {
                    padding: 46px 16px 17px 9px;
                    .seo__card-kd {
                        .seo__card-kd-proccent {
                            font-size: 20px;
                            line-height: 30px;
                        }
                    }
                    .seo__card-volume {
                        text-align: center;
                        .seo__card-volume-count {
                            font-size: 20px;
                            line-height: 30px;
                        }
                    }
                }
            }
            .seo__card-text {
                margin: 0 16px;
            }
            .seo__card-text-canada {
                padding: 0;
            }
            .seo__card-text-uk {
                padding: 0;
            }
        }
    }
}

@media only screen and (max-width: 723px) {
    .seo {
        .seo__row {
            .seo__card {
                .seo__card-head {
                    padding: 31px 10px;
                }
            }
        }
    }
}

@media only screen and (max-width: 635px) {
    .seo {
        margin-bottom: 40px;    
        padding: 0;
        .seo__title {
            margin-bottom: 30px;
            display: none;
        }
        .seo__titles--mobile {
            margin-bottom: 30px;
            display: block;
            width: 100%;
        }
        .seo__navigate {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 0px;
            .seo__titles {
                display: none;
            }
            .seo__icons {
                display: none;
            }
        }
        .seo__row {
            display: grid;
            padding: 0 20px;
            margin: 0 auto;
            grid-template-columns: repeat(1, 1fr);
            column-gap: 0px;
            row-gap: 30px;
            margin-bottom: 40px;
            .seo__card {
                width: 100%;
                height: 445px;
                position: relative;
                .seo__card-head {
                    .seo__card-volume-btn {
                        display: block;
                    }
                }
                .seo__card-body {
                    height: 204px;
                }
                .seo-btn {
                    button {
                        margin-left: 0;
                    }
                }
            }
        }
    }
    .seo__pagination {
        padding: 0 20px;
        margin: 0 auto;
        .seo__pagination-wrap {
            border: 1px solid #2A2B3A;
            border-radius: 50%;
            width: 56px;
            height: 56px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 25px;
            cursor: pointer;
            &:hover {
                background: #2A2B3A;
                border: 1px solid #2A2B3A;
                backdrop-filter: blur(4px);
            }
            &:hover .seo__pagination-icon {
                color: #fff;
            }
            .seo__pagination-icon {
                color: #9E9EAC;
                font-size: 25px;
            }
        }
    }
}