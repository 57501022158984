.seo__card {
    .seo__card-body {
        height: 204px;
        .seo__card-body-img {
            transition: 0.3s all;
            opacity: 1;
        }
        .seo__card-body-img--hidden {
            opacity: 0;
        }
        .seo__card-body-structure {
            width: 100%;
        }
    }
    .seo__card-subtitle {
        margin: 18px 26px 22px 26px;
        color: #fff;
    }
}

@media only screen and (max-width: 1060px) {
    .seo__card {
        .seo__card-title {
            margin: 12px 26px 12px 26px;
        }
        .seo__card-body {
            height: 210px;
        }
        .seo__card-subtitle {
            margin: 18px 26px 22px 26px;
            color: #fff;
        }
    }
}