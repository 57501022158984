.experience {
    margin-bottom: 85px;
    padding-top: 47px;
    .experience__block {
        max-width: 1180px;
        padding: 0 10px;
        margin: 0 auto;
        .experience__title {
            position: relative;
            margin-bottom: 55px;
            z-index: 13;
        }
        .experience__list {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            column-gap: 31px;
            margin-bottom: 87px;
        }
        .experience__text {
            text-align: start;
            max-width: 860px;
            margin: 0 auto;
            margin-bottom: 89px;
        }
    }
}

@media only screen and (max-width: 1060px) {
    .experience {
        padding-top: 0px;
        margin-bottom: 40px;
        .experience__block {
            max-width: 1018px;
            padding: 0 20px;
            .experience__title  {
                margin-bottom: 30px;
            }
            .experience__list {
                margin-bottom: 60px;
                .experience__card {
                    height: 220px;
                    .card {
                        img {
                            width: 65px;
                        }
                    }
                }
            }
            .experience__text {
                margin-bottom: 70px;
            }
        }
        .experience__skills {
            .swiper {
                gap: 0;
                .marquee {
                    gap: 0;
                }
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .experience {
        margin-bottom: 35px;
        .experience__block {
            max-width: 767px;
            .experience__title {
                margin-bottom: 34px;
                text-align: center;
            }
            .experience__list {
                column-gap: 19px;
                margin-bottom: 69px;
                .experience__card {
                    height: 165px;
                    .card {
                        img {
                            width: 45px;
                        }
                    }
                }
            }
            .experience__text {
                max-width: none;
                width: 100%;
                margin-bottom: 105px;
            }
        }
    }
}

@media only screen and (max-width: 520px) {
    .experience {
        .experience__block {
            .experience__title {
                margin-bottom: 30px;
                font-size: 24px;
                line-height: 50px;
                text-align: start;
            }
        }
    }
}

@media only screen and (max-width: 480px) {
    .experience {
        margin-bottom: 50px;
        .experience__block {
            padding: 0 20px;
            max-width: 490px;
            margin: 0 auto;
            .experience__title {
                margin-bottom: 30px;
                font-size: 24px;
                line-height: 50px;
            }
            .experience__list {
                grid-template-columns: repeat(1, 1fr);
                column-gap: 0px;
                row-gap: 30px;
                margin-bottom: 30px;
                .experience__card {
                    height: 231px;
                }
            }
            .experience__text {
                margin: 0 auto;
                margin-bottom: 36px;
            }
        }
        .experience__skills {
            margin-left: -20px;
        }
    }
}