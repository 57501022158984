@keyframes showModal {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.modal-wrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    z-index: 9999999;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    .modal {
        padding: 62px 52px 50px 52px;
        background: #161B22;
        border: 1px solid #2A2B3A;
        border-radius: 18px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        position: relative;
        max-width: 459px;
        max-height: 673px;
        animation: showModal 0.3s linear;
        .modal__close {
            position: absolute;
            top: 25px;
            left: 25px;
            color: #9E9EAC;
            width: 24px;
            height: 24px;
            cursor: pointer;
        }
        .modal__logo {
            width: 48px;
            height: 48px;
            background: #2A2B3A;
            border-radius: 8px;
            margin-bottom: 26px;
            padding: 14px;
            img {
                width: 22px;
                height: 20px;
            }
        }
        .modal__title {
            margin-bottom: 12px;
            font-size: 24px;
            line-height: 36px;
            color: #FFFFFF;
        }
        .modal__subtitle {
            margin-bottom: 32px;
            text-align: center;
        }
        .modal__input-wrap {
            width: 100%;
            position: relative;
            img {   
                position: absolute;
            }
        }
        .modal__input-wrap + .modal__input-wrap {
            margin-top: 18px;
        }
        .modal__input {
            width: 100%;
            background: #2A2B3A;
            border-radius: 8px;
            padding: 10px 55px 10px 16px;
            border: none;
            outline: none;
            font-size: 14px;
            line-height: 135%;
            letter-spacing: -0.01em;
            font-family: 'poppinsRegular', sans-serif;
            color: #fff;
            min-height: 50px;
            caret-color: #9747FF;
            &:focus {
                background: #3C3D4C;
                border: 2px solid #9747FF
            }
        }
        .modal__input-error {
            border: 2px solid #F015B0;
            &::placeholder {
                color: #F015B0;
            }
        }
        .input-error {
            color: #F015B0;
            position: absolute;
        }

        .modal__input-error ~ .modal__input-icon {
            filter: brightness(0) saturate(100%) invert(35%) sepia(92%) saturate(5583%) hue-rotate(300deg) brightness(92%) contrast(109%);
        }
        
        .modal__input-text {
            max-width: 100%;
            resize: none;
            max-height: 140px;
            right: 11px;
            top: 18px;
        }
        .modal__input-name {
            right: 15px;
            top: 50%;
            transform: translateY(-50%);
        }
        .modal__input-email {
            right: 11px;
            top: 50%;
            transform: translateY(-50%);
        }
        .modal__btn {
            width: 100%;
            background-color: #fff;
            color: #000;
            margin-top: 36px;
            transition: 0.2s all;
            &:hover {
                background-color: #9747FF;
                color: #fff;
            }
        }
    }
}
.modal__input_err {
    position: absolute;
    font-size: 12px;
    color: #F015B0;
}

@media only screen and (max-width: 500px) {
    .modal-wrap {
        .modal {
            width: 100vw;
            height: 100vh;
            position: fixed;
            top: 0;
            left: 0;
            max-height: none;
            max-width: none;
            border-radius: 0;
            justify-content: center;
            .modal__close {
                position: absolute;
                top: 85px;
            }
            button {
                margin-left: 0;
            }
        }
    }
}