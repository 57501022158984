.outsourcing {
    position: relative;
    margin-bottom: 85px;
    padding-top: 47px;
    overflow: hidden;
    .outsourcing__wrap {
        max-width: 1180px;
        padding: 0 10px;
        margin: 0 auto;
        .outsourcing__title {
            margin-bottom: 54px;
        }
        .outsourcing__table {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: repeat(3, 1fr);
            gap: 30px;
            padding: 10px 0;
            .outsourcing__table-wrapper {
                z-index: 2;
                height: 249px;
                .outsourcing__card {
                    transition: 0.2s all;
                    width: 100%;
                    height: 100%;
                    background: linear-gradient(140.55deg, rgba(42, 43, 58, 0.6) 1.17%, rgba(9, 11, 12, 0.6) 79.03%);
                    border: 1px solid #3C3D4C;
                    backdrop-filter: blur(2px);
                    border-radius: 20px;
                    padding: 40px 20px 30px 35px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-evenly;
                    align-items: start;
                    transform-style: preserve-3d;
                    cursor: pointer;
                    .outsourcing__img {
                        margin-bottom: 20px;
                        width: 50px;
                        position: relative;
                        transition: 0.2s;
                    }
                }
                .outsourcing__card-btn {
                    background: transparent;
                    backdrop-filter: none;
                    border: none;
                    padding-bottom: 0;
                    display: flex;
                    flex-direction: row;
                    align-items: end;
                }
            }
            .outsourcing__card-sharp {
                grid-column: 1/2;
                grid-row: 1/2;
            }
            .outsourcing__card-react {
                grid-column: 3/4;
                grid-row: 1/2;
            }
            .outsourcing__card-mob {
                grid-column: 2/3;
                grid-row: 2/3;
            }
            .outsourcing__card-java {
                grid-column: 1/2;
                grid-row: 3/4;
            }
            .outsourcing__card-php {
                grid-column: 2/3;
                grid-row: 3/4;
            }
            .outsourcing__card-btn {
                grid-column: 3/4;
                grid-row: 3/4;
                align-self: end;
            }
        }
    }
    .outsourcing__bg1 {
        position: absolute;
        top: 50%;
        left: -178px;
        z-index: 3;
        filter: blur(5px);
        transition: 0.3s all;
        &:hover {
            transform: scale(1.04);
            filter: blur(0);
        }
    }
    .outsourcing__bg2 {
        position: absolute;
        top: 20%;
        left: 46%;
        transform: translateX(-50%);
        transition: 0.3s all;
        &:hover {
            transform: scale(1.04) translateX(-48%);
        }
    }
    .outsourcing__bg3 {
        position: absolute;
        top: 50%;
        transform: translateY(-43%);
        right: 0;
        transition: 0.3s all;
        &:hover {
            transform: scale(1.04) translateY(-41%);
        }
    }
}

@media only screen and (max-width: 1060px) {
    .outsourcing {
        padding-top: 66px;
        margin-bottom: 70px;
        .outsourcing__wrap {
            max-width: 1018px;
            padding: 0 20px;
            .outsourcing__title {
                margin-bottom: 6px;
            }
            .outsourcing__table {
                grid-template-columns: repeat(3, 1fr);
                grid-template-rows: auto;
                gap: 24px;
                .outsourcing__card-sharp, .outsourcing__card-react, .outsourcing__card-mob, .outsourcing__card-java, .outsourcing__card-php, .outsourcing__card-btn {
                    grid-column: auto;
                    grid-row: auto;
                }
                .outsourcing__card-btn {
                   display: none;
                }
                .outsourcing__table-wrapper {
                    height: 271px;
                    .outsourcing__card {
                        padding: 40px 34px 35px 34px;
                        .outsourcing__card-name {
                            margin-bottom: 12px;
                        }
                    }
                } 
            }
        }
        .outsourcing__bg1 {
            top: 55%;
            left: -57px;
            width: 153px;
        }
        .outsourcing__bg2 {
            top: 48%;
            left: 46%;
            width: 157px;
        }
        .outsourcing__bg3 {
            top: 65%;
            right: 0;
            width: 283px;
        }
    }
}

@media only screen and (max-width: 830px) {
    .outsourcing {
        margin-bottom: 70px;
        padding-top: 70px;
        overflow: hidden;
        .outsourcing__wrap {
            padding: 0 20px;
            .outsourcing__title {
                margin-bottom: 6px;
            }
            .outsourcing__table {
                grid-template-columns: repeat(2, 1fr);
                grid-template-rows: auto;
                column-gap: 28px;
                row-gap: 24px;
                padding: 0;
            }
        }
        .outsourcing__bg1 {
            top: 87%;
            left: -57px;
            width: 118px;
            height: 116px;
        }
        .outsourcing__bg2 {
            top: 73%;
            left: 46%;
            width: 121px;
            height: 123px;
        }
        .outsourcing__bg3 {
            top: 85%;
            right: 0;
            width: 217.43px;
            height: 220.63px;
        }
    }
}

@media only screen and (max-width: 500px) {
    .outsourcing {
        padding: 0 20px;
        margin: 0 auto;
        position: relative;
        margin-bottom: 50px;
        padding-top: 0px;
        overflow: hidden;
        .outsourcing__wrap {
            max-width: 1148px;
            margin: 0 auto;
            padding: 0;
            .outsourcing__title {
                margin-bottom: 30px;
            }
            .outsourcing__table {
                display: grid;
                grid-template-columns: repeat(1, 1fr);
                grid-template-rows: auto;
                gap: 30px;
                padding: 0;
                .outsourcing__card-btn {
                    display: flex;
                    justify-content: center;
                 }
                .outsourcing__table-wrapper {
                    position: relative;
                    z-index: 10;
                    height: 249px;
                    pointer-events: none;
                    .outsourcing__card {
                        padding: 40px 35px 35px 35px;
                        .outsourcing__img {
                            width: 40px;
                        }
                        .outsourcing__card-name {
                            color: #fff;
                            margin-bottom: 12px;
                        }
                    }
                }
                .outsourcing__card-sharp, .outsourcing__card-react, .outsourcing__card-mob, .outsourcing__card-java, .outsourcing__card-php, .outsourcing__card-btn {
                    grid-column: auto;
                    grid-row: auto;
                }
                .outsourcing__card-btn {
                    align-self: end;
                }
            }
        }
        .outsourcing__bg1, .outsourcing__bg2, .outsourcing__bg3 {
            display: none;
        }
    }
}