.location {
    position: relative;
    padding-bottom: 329px;
    background: url('../../../assets/img/locationBg.webp') no-repeat bottom;
    padding-top: 17px;
    background-size: cover;
    .location__title {
        max-width: 1180px;
        padding: 0 10px;
        margin: 0 auto;
        z-index: 14;
        position: relative;
        margin-bottom: 54px;
    }
    .location__content {
        max-width: 1180px;
        padding: 0 10px;
        margin: 0 auto;
        display: flex;
        justify-content: start;
        align-items: start;
        z-index: 3;
        position: relative;
        .location__selector {
            display: flex;
            justify-content: start;
            align-items: center;
            background: #000000;
            border: 1px solid #2A2B3A;
            border-radius: 8px;
            padding: 8px;
            margin-right: 46px;
            .location__selector-uk {
                display: flex;
                align-items: center;
                background: transparent;
                border: 1px solid transparent;
                border-radius: 4px;
                padding: 8px;
                cursor: pointer;
                .location__selector-img {
                    width: 48px;
                    height: 32px;
                    margin-right: 7px;
                }
                .location__selector-name {
                    font-size: 14px;
                    line-height: 20px;
                    color: #9E9EAC;
                }
            }
            .location__selector-uk + .location__selector-uk {
                margin-left: 16px;
            }
            .location__selector-poland {
                display: flex;
                align-items: center;
                cursor: pointer;
                background: transparent;
                border: 1px solid transparent;
                border-radius: 4px;
                margin-right: 0;
                padding: 8px;
                .location__selector-img {
                    margin-right: 12px;
                }
                .location__selector-name {
                    font-size: 14px;
                    line-height: 20px;
                    margin-right: 24px;
                    color: #9E9EAC;
                }
            }
            .location__selector--active {
                background: #2A2B3A;
                border: 1px solid #2A2B3A;
                border-radius: 4px;
                padding: 8px;
                .location__selector-name {
                    color: #FFFFFF;
                }
            }
        }
        .location__selector--mobile {
            display: none;
        }
        .location__card {
            border: 1px solid #2A2B3A;
            border-radius: 8px;
            width: 480px;
            height: 426px;
            .location__card-list {
                padding: 55px 0 48px 0;
                height: 319px;
            }
            .location__card-item {
                display: flex;
                justify-content: start;
                align-items: center;
                margin: 0 25px;
                .location__card-icon {
                    margin-right: 24px;
                }
                .location__inf {
                    font-size: 24px;
                    line-height: 36px;
                    display: flex;
                    align-items: center;
                    color: #FFFFFF;
                }
            }
            .location__card-item-poland {
                margin-right: 45%;
            }
            .location__card-item + .location__card-item {
                margin-top: 36px;
            }
            .location__btn {
                padding: 29px 82px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-top: 1px solid #2A2B3A;
            }
        }
    }
}

@media only screen and (max-width: 1920px) {
    .location {
        background-size: contain;
    }
}

@media only screen and (max-width: 1060px) {
    .location {
        padding-top: 0px;
        padding-bottom: 230px;
        .location__title {
            max-width: 1018px;
            padding: 0 20px;
            margin-bottom: 8px;
        }
        .location__content {
            max-width: 1018px;
            padding: 0 20px;
            .location__card {
                .location__card-item {
                    .location__inf {
                        font-size: 20px;
                        line-height: 30px;
                    }
                }
                .location__card-item-poland {
                    margin-right: 53%;
                }
            }
        }
    }
}

@media only screen and (max-width: 877px) {
    .location {
        padding-bottom: 210px;
        .location__title {
            max-width: 767px;
            margin-bottom: 30px;
        }
        .location__content {
            max-width: 767px;
            padding: 0 20px;
            flex-direction: column;
            align-items: center;
            .location__selector {
                margin-right: 0;
                margin-bottom: 24px;
            }
            .location__card {
                width: 337px;
                height: 320px;
                .location__card-list {
                    padding: 30px 0 30px 0;
                    height: 239px;
                }
                .location__card-item {
                    .location__inf {
                        font-size: 16px;
                        line-height: 24px;
                    }
                }
                .location__card-item-poland {
                    margin-right: 43%;
                }
                .location__btn {
                    padding: 15px 24px;
                }
            }
        }
    }
}

@media only screen and (max-width: 500px) {
    .location {
        padding-bottom: 60px;
        background: none;
        padding-top: 0px;
        .location__title {
            margin-bottom: 9px;
        }
        .location__content {
            display: block;
            padding: 0;
            .location__selector {
                display: none;
            }
            .location__selector--mobile {
                display: block;
                margin-bottom: 19px;
            }
            .location__card {
                max-width: 100%;
                margin: 0 20px;
                .location__card-item {
                    .location__card-icon {
                        margin-right: 20px;
                    }
                    .location__inf {
                        font-size: 16px;
                        line-height: 24px;
                    }
                }
                .location__card-item + .location__card-item {
                    margin-top: 30px;
                }
                .location__btn {
                    padding: 15px 24px;
                    button {
                        margin-left: 0;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 369px) {
    .location {
        .location__content {
            .location__card {
                width: auto;
                .location__card-item-poland {
                    margin-right: 40%;
                }
            }
        }
    }
}
@media only screen and (max-width: 351px) {
    .location {
        .location__content {
            .location__card {
                width: auto;
                .location__card-item-poland {
                    margin-right: 37%;
                }
            }
        }
    }
}
@media only screen and (max-width: 336px) {
    .location {
        .location__content {
            .location__card {
                width: auto;
                .location__card-item-poland {
                    margin-right: 35%;
                }
            }
        }
    }
}
@media only screen and (max-width: 326px) {
    .location {
        .location__content {
            .location__card {
                width: auto;
                .location__card-item-poland {
                    margin-right: 33%;
                }
            }
        }
    }
}
@media only screen and (max-width: 319px) {
    .location {
        .location__content {
            .location__card {
                width: auto;
                .location__card-item-poland {
                    margin-right: 30%;
                }
            }
        }
    }
}
@media only screen and (max-width: 308px) {
    .location {
        .location__content {
            .location__card {
                width: auto;
                .location__card-item-poland {
                    margin-right: 27%;
                }
            }
        }
    }
}