.experience__skills-card {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px;
    white-space: nowrap;
    margin-right: 70px;
    .experience__skills-img {
        margin-right: 25px;
    }
}

@media only screen and (max-width: 1060px) {
    .experience__skills-card {
        width: auto;
        height: auto;
        .experience__skills-img {
            margin-right: 11px;
            width: 45px;
            height: 45px;
        }
        .experience__skills-name {
            font-size: 15px;
            line-height: 135%;
            letter-spacing: -0.01em;
            color: #FFFFFF;
        }
    }
}

@media only screen and (max-width: 500px) {
    .experience__skills-card {
        white-space: nowrap;
        margin-right: 70px;
        .experience__skills-img {
            margin-right: 16px;
        }
        .experience__skills-name {
            font-size: 15px;
            line-height: 135%;
            letter-spacing: -0.01em;
            color: #FFFFFF;
        }
    }
}