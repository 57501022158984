.experience__card {
    height: 266px;
    &:nth-child(1) .card {
        background-image: url('../../../assets/img/expCard1.webp');
    }
    &:nth-child(2) .card {
        background-image: url('../../../assets/img/expCard2.webp');
    }
    &:nth-child(3) .card {
        background-image: url('../../../assets/img/expCard3.webp');
    }
    .card {
        width: 100%;
        height: 100%;
        transition: .3s;
        overflow: hidden;
        border-radius: 20px;
        background-color: linear-gradient(180deg, rgba(0, 0, 0, 0) 40.63%, #000000 81.25%);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border: 1px solid #2A2B3A;
        .experience__card-item {
            height: 100%;
        }
        .experience__card-title {
            position: absolute;
            bottom: 24px;
            left: 50%;
            transform: translateX(-50%);
            color: #FFFFFF;
            white-space: nowrap;
        }
        img {
            position: absolute;
            bottom: 50%;
            left: 50%;
            transform: translateX(-50%);
        }
    }
}

@media only screen and (max-width: 650px) {
    .experience__card {
        pointer-events: none;
        .card {
            .experience__card-title {
                white-space: normal;
            }
        }
    }
}

@media only screen and (max-width: 500px) {
    .experience__card {
        .card {
            .experience__card-title {
                position: absolute;
                bottom: 18px;
                left: 50%;
                transform: translateX(-50%);
                color: #FFFFFF;
                width: 100%;
                text-align: center;
            }
            img {
                width: 63px;
                height: 55px;
            }
        }
    }
}