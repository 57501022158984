.welcome {
    padding: 0px 10px;
    display: flex;
    justify-content: space-between;
    align-items: end;
    max-width: 1180px;
    margin: 0 auto;
    padding-top: 260px;
    margin-bottom: 135px;
    overflow: hidden;
    .welcome__bg {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        max-width: none;
        max-height: 100%;
        width: 100vw;
        height: 100%;
    }
    .welcome__info {
        text-align: start;
        padding-bottom: 20px;
        z-index: 3;
        max-width: 418px;
        .welcome__skills {
            display: flex;
            justify-content: start;
            align-items: center;
            margin-bottom: 40px;
            gap: 32px;
            .welcome__skills-item {
                background: #3C3D4C;
                border-radius: 100px;
                padding: 3px 20px;
                text-align: center;
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;
                color: #fff;
                cursor: default;
                &:hover {
                    background-color: #9747FF;
                }
            }
        }
        .welcome__title {
            margin-bottom: 40px;
        }
        .welcome__subtitle {
            margin-bottom: 29px;
        }
    }
    .welcome__cube-mobile {
        display: none;
        width: 239px;
        height: 200px;
        margin: 50px auto 30px auto;
    }
}

@media only screen and (max-width: 1060px) {
    .welcome {
        max-width: 1018px;
        padding: 160px 20px 0 20px;
        margin-bottom: 109px;
        align-items: center;
        .welcome__info {
            .welcome__title {
                font-size: 42px;
            }
            .welcome__subtitle {
                margin-bottom: 40px;
            }
        }
        .welcome__bg {
            top: -60px;
        }
    }
}

@media only screen and (max-width: 768px) {
    .welcome {
        .welcome__cube-mobile {
            display: block;
        }
    }
}

@media only screen and (max-width: 620px) {
    .welcome {
        flex-direction: column-reverse;
        padding: 0 20px;
        padding-top: 35px;
        margin-bottom: 50px;
        align-items: end;
        .welcome__info {
            max-width: none;
            .welcome__skills {
                justify-content: space-between;
                margin-bottom: 30px;
                gap: 21px;
                flex-wrap: wrap;
                .welcome__skills-item {
                    font-size: 10px;
                    line-height: 15px;
                    height: 22px;
                    color: #fff;
                    cursor: default;
                    &:hover {
                        background-color: #9747FF;
                    }
                }
            }
            .welcome__title {
                margin-bottom: 30px;
                font-size: 42px;
                line-height: 100%;
            }
            .welcome__subtitle {
                margin-bottom: 29px;
                color: #E4E5E6;
            }
            .welcome__contact {
                width: 100%;
                display: flex;
                justify-content: center;
            }
        }
        .welcome__cube-wrap {
            width: 100%;
        }
    }
}