.cookies {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #000000;
    border-top: 1px solid #2A2B3A;
    backdrop-filter: blur(2px);
    z-index: 999999;
    .cookies__content {
        padding: 40px 20px 27px 20px;
        max-width: 1118px;
        margin: 0 auto;
        .cookies__text {
            color: #FFFFFF;
            margin-bottom: 17px;
            z-index: 2;
            a {
                color: #fff;
                text-decoration: underline;
                cursor: pointer;
            }
        }
        .cookies__button-container {
            display: flex;
            justify-content: end;
            align-items: center;
            width: 100%;
            z-index: 2;
            .cookies__btn {
                width: 205px;
                height: 50px;
                padding: 14px 16px 14px 16px;
                border-radius: 8px;
                text-align: center;
                color: #fff;
                letter-spacing: -0.01em;
                border: none;
                outline: none;
                font-size: 16px;
                line-height: 135%;
                white-space: nowrap;
                cursor: pointer;
                a {
                    text-decoration: none;
                    color: #fff;
                }
            }
            .cookies__btn-settings {
                background-color: transparent;
            }
            .cookies__btn-accept {
                margin-left: 30px;
                background-color: #2A2B3A;
                border: 1px solid #2A2B3A;
                box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
            }
        }
    }
}

@media only screen and (max-width: 500px) {
    .cookies {
        padding: 40px 5px 27px 5px;
        .cookies__text {
            margin-bottom: 25px;
        }
        .cookies__button-container {
            .cookies__btn {
                width: 160px;
                height: 50px;
                padding: 10px 13px 10px 12px;
            }
            .cookies__btn-accept {
                margin-left: 20px;
            }
        }
    }
}