.welcome__cube, .welcome__cube--hidden {
    z-index: 3;
    display: block;
}
.welcome__cube--hidden {
    display: none !important;
}

video {
    width: 450px;
}

@media only screen and (max-width: 1060px) {
    video {
        width: 300px;
    }
}

@media only screen and (max-width: 730px) {
    video {
        width: 200px;
    }
}

@media only screen and (max-width: 620px) {
    video {
        margin: 40px auto;
    }
}