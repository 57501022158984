.business {
    max-width: 1180px;
    padding: 30px 10px;
    margin: 0 auto;
    margin-bottom: 38px;
    .business__title {
        margin-bottom: 54px;
    }
    .business__model {
        display: grid;
        grid-template-columns: minmax(204px, 368px) 265px minmax(204px, 465px);
        grid-template-rows: repeat(2, 1fr);
        gap: 30px;
        .business__big-coubes {
            grid-column: 1/2;
            grid-row: 1/3;
            position: relative;
        }
        .business__cards {
            position: relative;
            grid-column: 2/3;
            grid-row: 1/3;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: column;
            .business__card + .business__card {
                margin-top: 30px;
            }
            .business__card {
                background: linear-gradient(140.55deg, rgba(42, 43, 58, 0.6) 1.17%, rgba(9, 11, 12, 0.6) 79.03%);
                border: 1px solid #3C3D4C;
                backdrop-filter: blur(2px);
                border-radius: 20px;
                width: 100%;
                height: 100%;
                padding: 60px 34px 50px;
                max-height: 206px;
                white-space: nowrap;
                .business__outstaffing-icon, .business__outsorcing-icon {
                    margin-bottom: 21px;
                    width: 40px;
                    height: 40px;
                }
                .business__outstaffing-title, .business__team-title, .business__outsorcing-title {
                    color: #fff;
                    font-weight: 400;
                    font-size: 24px;
                    line-height: 135%;
                    letter-spacing: -0.01em;
                }
            }
            .business__outsorcing {
                position: relative;
                .business__outsorcing-bg {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
            }
            .business__team {
                padding-top: 17px;
                .business__team-bg {
                    display: flex;
                    margin: 0 auto;
                    transform: translateX(0);
                }
            }
        }
        .business__cards--mobile {
            display: none;
            max-width: 100vw;
            .swiper {
                gap: 30px;
                min-height: auto;
                .marquee {
                    gap: 30px;
                }
            }
            .marquee {
                min-width: none;
            }
            .business__outsorcing {
                position: relative;
                .business__outsorcing-bg {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
            }
            .business__team-bg {
                position: absolute;
                top: 20px;
            }
            .business__card {
                width: 265px;
                height: 206px;
                background: linear-gradient(140.55deg, rgba(42, 43, 58, 0.6) 1.17%, rgba(9, 11, 12, 0.6) 79.03%);
                border: 1px solid #3C3D4C;
                backdrop-filter: blur(2px);
                border-radius: 20px;
                white-space: nowrap;
                padding: 61px 34px 50px 34px;
                background-position: center;
                display: flex;
                align-items: start;
                justify-content: end;
                flex-direction: column;
                .business__outstaffing-icon, .business__outsorcing-icon {
                    margin-bottom: 21px;
                    width: 40px;
                    height: 40px;
                }
                .business__outstaffing-title, .business__team-title, .business__outsorcing-title {
                    color: #fff;
                }
            }
        }
        .business__keyboard {
            grid-column: 3/4;
            grid-row: 1/2;
            position: relative;
            height: 334px;
        }
        .business__mini-coubes {
            grid-column: 3/4;
            grid-row: 2/3;
            position: relative;
            height: 334px;
        }
    }
    .business__card--big {
    background: url(../../../assets/img/bigCoubes.webp) no-repeat center;
}
.business__card--keyboard {
    background: url(../../../assets/img/keyboard.webp) no-repeat center;
}
.business__card--mini {
    background: url(../../../assets/img/miniCoubes.webp) no-repeat center;
}

.business__card {
    background-size: cover;
    width: 100%;
    height: 100%;
    transition: 0.3s all;
    border-radius: 16px;
    border: 0.828152px solid #2A2B3A;
}
}
.business__outsorcing-circle {
    position: absolute;
    top: -114px;
    left: 8px;
    width: 245px;
    height: 133px;
    background: #9747ff4d;
    mix-blend-mode: multiply;
    filter: blur(69px);
    border-radius: 50%;
} 

.business__team-title {
    white-space: break-spaces;
}

@media only screen and (max-width: 1165px) {
    .business {
        max-width: 1018px;
        padding: 0 20px;
        margin-bottom: 66px;
        .business__title {
            margin-bottom: 33px;
        }
        .business__model {
            grid-template-columns: minmax(204px, 368px) 222px minmax(204px, 465px);
            gap: 25px;
            .business__cards {
                .business__card + .business__card {
                    margin-top: 24px;
                }
                .business__card {
                    padding: 50px 34px 40px;
                    max-height: 177px;
                    .business__outstaffing-icon, .business__outsorcing-icon {
                        margin-bottom: 24px;
                        width: 44px;
                    }
                    .business__outstaffing-title, .business__team-title, .business__outsorcing-title {
                        font-size: 20px;
                        line-height: 135%;
                        letter-spacing: -0.01em;
                    }
                }
                .business__team {
                    padding: 50px 28px 40px;
                    display: flex;
                    align-items: end;
                    .business__team-bg {
                        position: absolute;
                        top: 10px;
                    }
                }
            }
            .business__cards--mobile {
                .business__card {
                    .business__outstaffing-title {
                        font-weight: 400;
                        font-size: 24px;
                        line-height: 135%;
                        letter-spacing: -0.01em;
                    }
                }
            }
            .business__keyboard, .business__mini-coubes {
                height: 277px;
            }
        }
    }
}

@media only screen and (max-width: 716px) {
    .business {
        margin: 0 auto;
        padding: 0;
        margin-bottom: 50px;
        .business__title {
            padding: 0 20px;
            margin: 0 auto;
            margin-bottom: 24px;
        }
        .business__model {
            display: flex;
            gap: 30px;
            .business__big-coubes {
                display: none;
            }
            .business__cards {
                display: none;
            }
            .business__keyboard {
                display: none;
            }
            .business__mini-coubes {
                display: none;
            }
            .business__cards--mobile {
                display: block;
            }
        }
        .business__card--big {
        background: url(../../../assets/img/bigCoubes.webp) no-repeat center;
    }
    .business__card--keyboard {
        background: url(../../../assets/img/keyboard.webp) no-repeat center;
    }
    .business__card--mini {
        background: url(../../../assets/img/miniCoubes.webp) no-repeat center;
    }
    .business__card {
        position: relative;
        background-size: cover;
        width: 100%;
        height: 100%;
        transition: 0.3s all;
        .light{
            top: 0;
            left: 0;
            opacity: 0;
            width: 100%;
            height: 100%;
            display: block;
            position: absolute;
            transition: opacity .3s;
            background: radial-gradient(circle at 52.8095px 0px, rgb(255, 255, 255), transparent);
            border-radius: 15px;
        }
        &:hover{
            .light{
                opacity: 0.4;
            }
        }
    }
    }
    .business__outsorcing-circle {
        position: absolute;
        top: -114px;
        left: 8px;
        width: 245px;
        height: 133px;
        background: #9747ff4d;
        mix-blend-mode: multiply;
        filter: blur(69px);
        border-radius: 50%;
    } 
}