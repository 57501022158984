.footer {
    border-top: 1px solid #3C3D4C;
    background-color: #000;
    .footer__content {
        margin: 0 auto;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        max-width: 1180px;
        margin: 0 auto;
        padding: 50px 10px 60px 10px;
        .footer__logo {
            display: flex;
            align-items: center;
            transform: translateY(-20px);
            text-decoration: none;
            .footer__logo-img {
                margin-right: 13px;
                filter: brightness(0) saturate(100%) invert(71%) sepia(8%) saturate(350%) hue-rotate(201deg) brightness(89%) contrast(87%);
            }
            .footer__logo-name {
                font-family: 'poppinsBold', sans-serif;
                color: #9E9EAC;
                font-weight: 700;
                font-size: 24px;
                line-height: 135%;
            }
        }
        .footer__adress-title, .footer__about-title, .footer__folow-title {
            font-family: 'poppinsBold', sans-serif;
            margin-bottom: 20px;
            font-weight: 700;
            font-size: 12px;
        }
        .footer__adress-list, .footer__about-list {
            max-width: 170px;
        }
        .footer__adress-item, .footer__about-item, .footer__folow_item {
            font-size: 12px;
            margin-bottom: 15px;
            cursor: pointer;
            a {
                text-decoration: none;
                letter-spacing: -0.01em;
                color: #9E9EAC;
            }
        }
        .footer__about-item a {
            &:hover {
                color: #fff;
            }
        }
        .footer__folow-list {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .footer__folow-item {
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                &:hover .footer__folow-img {
                    filter: brightness(0) saturate(100%) invert(33%) sepia(74%) saturate(4015%) hue-rotate(251deg) brightness(101%) contrast(101%);
                }
            }
            .footer__folow-item + .footer__folow-item {
                margin-left: 42px;
            }
        }
    }
    .footer__container-down {
        border-top: 1px solid #3C3D4C;
        padding: 30px 0;
        .footer__terms-list {
            max-width: 1180px;
            padding: 0 10px;
            margin: 0 auto;
            display: flex;
            justify-content: start;
            align-items: center;
            .footer__terms-item {
                font-size: 12px;
                letter-spacing: -0.01em;
            }
            .footer__terms-item + .footer__terms-item {
                margin-left: 16px;
            }
            .footer__terms-item--mob {
                display: none;
            }
        }
    }
}

@media only screen and (max-width: 1060px) {
    .footer {
        .footer__content {
            max-width: 1018px;
            padding: 50px 20px 60px 20px;
            .footer__folow-list {
                .footer__folow-item + .footer__folow-item {
                    margin-left: 31px;
                }
            }
        }
        .footer__container-down {
            padding: 28px 0 34px 0;
            .footer__terms-list {
                max-width: 1018px;
                padding: 0 20px;
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                .footer__terms-item {
                    &:first-child {
                        grid-column: 2/3;
                    }
                }
                .footer__terms-item + .footer__terms-item {
                    margin-left: 0px;
                }
                .footer__terms-item-terms {
                    margin-left: -120px !important;
                }
            }
        }
    }
}

@media only screen and (max-width: 890px) {
    .footer {
        .footer__container-down {
            padding: 28px 0 34px 0;
            border-top: none;
            .footer__terms-list {
                max-width: 1018px;
                padding: 0 20px;
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                .footer__terms-item {
                    white-space: nowrap;
                    &:first-child {
                        grid-column: 1/2;
                    }
                }
                .footer__terms-item {
                    margin-right: 10px;
                }
                .footer__terms-item-terms {
                    margin-left: -90px !important;
                }
            }
        }
    }
}

@media only screen and (max-width: 789px) {
    .footer {
        .footer__content {
            max-width: 767px;
            padding: 38px 0px 60px 0;
            grid-template-columns: repeat(3, 1fr);
            .footer__logo {
                margin-left: 20px;
            }
            .footer__about {
                margin-right: 20px;
            }
            .footer__folow {
                .footer__folow-title {
                    transform: translateY(-75px);
                    margin-left: 20px;
                }
                .footer__folow-list {
                    border-top: 1px solid #3C3D4C;
                    border-bottom: 1px solid #3C3D4C;
                    position: absolute;
                    width: 100%;
                    padding: 20px;
                    .footer__folow-item + .footer__folow-item {
                        margin-left: 31px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 590px) {
    .footer {
        .footer__container-down {
            .footer__terms-list {
                .footer__terms-item-terms {
                    margin-left: -40px !important;
                }
            }
        }
    }
}

@media only screen and (max-width: 500px) {
    .footer {
        .footer__content {
            grid-template-columns: repeat(1, 1fr);
            padding: 50px 0px 36px 0px;
            .footer__logo {
                display: none;
            }
            .footer__adress {
                display: none;
            }
            .footer__about {
                border-bottom: 1px solid #3C3D4C;
                margin-bottom: 36px;
                margin-right: 0;
            }
            .footer__adress-title, .footer__about-title, .footer__folow-title {
                display: none;
            }
            .footer__adress-list, .footer__about-list {
                padding: 0 20px;
            }
            .footer__adress-item, .footer__about-item, .footer__folow_item {
                margin-bottom: 30px;
                cursor: pointer;
                a {
                    text-decoration: none;
                    letter-spacing: -0.01em;
                    color: #9E9EAC;
                }
            }
            .footer__about-item a {
                &:hover {
                    color: #fff;
                }
            }
            .footer__folow {
                .footer__folow-list {
                    padding: 0 20px;
                    margin: 0 auto;
                    border-top: none;
                    border-bottom: none;
                    position: static;
                    width: auto;
                }
            }
        }
        .footer__container-down {
            border-top: 1px solid #3C3D4C;
            .footer__terms-list {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-row: auto;
                row-gap: 33px;
                column-gap: 18px;
                .footer__terms-item--mob {
                    display: block;
                }
                .footer__terms-item {
                    white-space: normal;
                    font-size: 14px;
                    line-height: 135%;
                    letter-spacing: -0.01em;
                }
                .footer__terms-item + .footer__terms-item {
                    margin-left: 0px;
                }
                .footer__terms-item--reversed {
                    font-size: 12px;
                    grid-column: 1/2;
                    grid-row: 2/3;
                }
                .footer__terms-logo {
                    display: flex;
                    align-items: center;
                    text-decoration: none;
                    .footer__terms-img {
                        margin-right: 13px;
                        width: 30px;
                        height: 27px;
                        filter: brightness(0) saturate(100%) invert(71%) sepia(8%) saturate(350%) hue-rotate(201deg) brightness(89%) contrast(87%);
                    }
                    .footer__terms-name {
                        font-family: 'poppinsBold', sans-serif;
                        color: #9E9EAC;
                        font-weight: 700;
                        font-size: 24px;
                        line-height: 135%;
                    }
                }
                .footer__terms-item-terms {
                    margin-left: 0px !important;
                }
            }
        }
    }
}