.header {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    padding: 23px 180px 23px 140px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 100;
    border-bottom: 1px solid transparent;
    transition: 0.3s ease-in;
    z-index: 999999;
    .header__logo {
        display: flex;
        justify-content: start;
        align-items: center;
        z-index: 2;
        margin-right: 35px;
        cursor: pointer;
        .header__logo-img {
            margin-right: 13px;
            width: 30px;
            height: 27px;
        }
        .header__logo-name {
            font-family: 'poppinsBold', sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 135%;
            text-align: center;
            letter-spacing: 0.1em;
            color: #FFFFFF;
            white-space: nowrap;
        }
    }
    .header__list {
        display: flex;
        justify-content: right;
        align-items: center;
        margin-right: 130px;
        z-index: 101;
        .header__list-item {
            color: #fff;
            margin-right: 70px;
            transition: 0.1s all;
            cursor: pointer;
            z-index: 100;
            &:hover {
                color: #9747FF;
            }
            a {
                text-decoration: none;
                z-index: 100;
                color: inherit;
            }
        }
    }
}
.header__active {
    background: rgba(0, 0, 0, 0.6);
    border-bottom: 1px solid #3C3D4C;
    backdrop-filter: blur(7.5px);
}

.header__burger {
    display: none;
    width: 30px;
    height: 30px;
    z-index: 2;
}
.header__burger--close {
    width: 22px;
    height: 22px;
    z-index: 2;
}

@media only screen and (max-width: 1024px) {
    .header {
        padding: 23px 20px;
        .header__list {
            margin-right: 0;
            .header__list-item {
                margin-right: 0;
            }
            .header__list-item + .header__list-item {
                margin-left: 70px;
            }
        }
    }
}

@media only screen and (max-width: 800px) {
    .header {
        padding: 23px 20px 20px 20px;
        .header__burger {
            display: block;
        }
        .header__nav {
            display: none;
        }
    }
}