.seo__card-head-analysis {
    padding: 10px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .seo__card-header-left {
        display: flex;
        justify-content: start;
        align-items: center;
        .seo__card-flag {
            width: 54px;
            height: 35px;
            margin-right: 16px;
        }
        .seo__card-country {
            margin-right: 7px;
            font-size: 40px;
            line-height: 60px;
        }
        .seo__card-arrow {
            font-size: 19px;
            color: #2A2B3A;
            transform: translateY(-15px);
        }
    }
    .seo__card-volume-btn {
        border: none;
        outline: none;
        padding: 3px 12px;
        text-align: center;
        background: #361E54;
        border-radius: 16px;
        font-size: 12px;
        line-height: 18px;
        color: #9747FF;
    }
    .uk-volume {
        background: #131F31;
        color: #175CD3;
    }
    .canada-volume {
        background: #31132E;
        color: #C11574;
    }
}
.seo__card-statistic {
    position: relative;
    padding: 103px 48px 22px 34px;
    border-top: 1px solid #2A2B3A;
    border-bottom: 1px solid #2A2B3A;
    display: flex;
    justify-content: space-between;
    align-items: end;
    height: 204px;
    .seo__graphic-bg {
        width: 100%;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
    }
    .seo__card-result {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        z-index: 3;
        .seo__card-result-img {
            background: #2A2B3A;
            border: 1px solid #3C3D4C;
            border-radius: 1000px;
            width: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 50px;
        }
        .seo__card-result-title {
            font-size: 14px;
            line-height: 20px;
        }
    }
    .seo__card-kd {
        z-index: 3;
        text-align: center;
        .seo__card-kd-proccent {
            font-weight: 500;
            font-size: 24px;
            line-height: 36px;
            color: #FFFFFF;
        }
        .seo__card-kd-title {
            font-size: 14px;
            line-height: 20px;
        }
    }
    .seo__card-volume {
        z-index: 3;
        text-align: center;
        .seo__card-volume-count {
            font-weight: 500;
            font-size: 24px;
            line-height: 36px;
            color: #fff;
        }
        .seo__card-volume-title {
            font-size: 14px;
            line-height: 20px;
        }
    }
}

@media only screen and (max-width: 1060px) {
    .seo__card-statistic {
        height: 182px;
    }
}

@media only screen and (max-width: 960px) {
    .seo__card-head-analysis {
        .seo__card-volume-btn {
            display: none;
        }
    }
    .seo__card-statistic {
        height: 135px;
    }
}

@media only screen and (max-width: 635px) {
    .seo__card-statistic {
        height: 204px;
    }
}