.seo__card {
    .seo__card-body {
        height: 204px;
        .seo__card-body-img {
            transition: 0.3s all;
            opacity: 1;
        }
        .seo__card-body-img--hidden {
            opacity: 0;
        }
        .seo__card-body-structure {
            width: 100%;
        }
    }
    .seo__card-subtitle {
        margin: 0px 26px 20px 26px;
        color: #fff;
    }
}

@media only screen and (max-width: 1060px) {
    .seo {
        .seo__row {
            .seo__card-body {
                height: 182px;
            }
        }
    }
    .seo__card {
        .seo__card-title {
            margin: 0;
            display: flex;
            align-items: center;
            padding: 12px 26px;
            height: 70px;
        }
    }
}

@media only screen and (max-width: 768px) {
    .seo__card {
        .seo__card-body {
            height: 135px;
        }
    }
}

@media only screen and (max-width: 1060px) {
    .seo__card {
        .seo__card-title {
            padding: 0;
        }
    }
}

@media only screen and (max-width: 500px) {
    .seo__card {
        .seo__card-body {
            height: 135px;
            .seo__card-body-img {
                height: 135px;
            }
        }
    }
}
