.scheme {
    position: relative;
    margin: 0 auto;
    max-width: 1180px;
    padding: 0 10px;
    margin-bottom: 55px;
    .scheme__title {
        margin-bottom: 118px;
    }
    .scheme__content {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        .scheme__list {
            margin-right: 107px;
            display: flex;
            justify-content: start;
            align-items: start;
            .scheme__row-icons {
                margin-right: 116px;
            }
            .scheme__row-example {
                display: flex;
                justify-content: start;
                align-items: center;
                position: absolute;
                left: -350px;
                top: 30%;
                .scheme__row-icon-wrap {
                    width: 84px;
                    height: 84px;
                    border-radius: 50%;
                    background: linear-gradient(140.55deg, rgba(42, 43, 58, 0.6) 1.17%, rgba(9, 11, 12, 0.6) 79.03%);
                    border: 1px solid #2A2B3A;
                    backdrop-filter: blur(2px);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                .scheme__triangles--transform {
                    transform: rotate(270deg);
                    position: relative;
                    top: 0px;
                    left: 24px;
                    margin-left: 8px;
                }
            }
            .scheme__row-cards {
                margin-right: 74px;
                .scheme__row-card + .scheme__row-card {
                    margin-top: 80px;
                }
                .scheme__row-card {
                    width: 360px;
                    .scheme__row-title {
                        font-size: 32px;
                        line-height: 48px;
                        color: #FFFFFF;
                        margin-bottom: 26px;
                    }
                    .scheme__row-text {
                        font-size: 24px;
                        line-height: 36px;
                        color: #9E9EAC;
                    }
                    .scheme__row-text--active {
                        color: #fff;
                    }
                }
            }
        }
        .scheme__ilustration {
            .scheme__ilustration-content {
                transform: translateY(-160px);
                width: 682px;
                height: 748px;
                .sheme__cards-wrap {
                    display: flex;
                    align-items: center;
                    justify-content: start;
                    .scheme__recents {
                        margin-top: 110px;
                        position: relative;
                        padding: 27px 71px 43px 28px;
                        background: #161B22;
                        border: 1px solid #2A2B3A;
                        border-radius: 12px;
                        margin-right: 52px;
                        width: 315px;
                        .scheme__recents-title {
                            color: #FFFFFF;
                            margin-bottom: 30px;
                            margin-left: 25px;
                        }
                        .scheme__recents-list {
                            display: flex;
                            flex-direction: column;
                            align-items: start;
                            justify-content: center;
                            .sheme__recents-item {
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                .sheme__recents-icon {
                                    margin-right: 25px;
                                    width: 44px;
                                }
                                .sheme__recents-info {
                                    text-align: start;
                                    .sheme__recents-item-name {
                                        color: #fff;
                                        margin-bottom: 5px;
                                    }
                                    .sheme__recents-item-subtitle {
                                        font-weight: 400;
                                        font-size: 12px;
                                        line-height: 20px;
                                        letter-spacing: 0.5px;
                                        color: #FFFFFF;
                                        mix-blend-mode: normal;
                                        opacity: 0.5
                                    }
                                }
                            }
                            .sheme__recents-item + .sheme__recents-item {
                                margin-top: 40px;
                            }
                        }
                    }
                    .sheme__plan {
                        margin-top: 230px;
                        position: relative;
                        background: #161B22;
                        border: 1px solid #2A2B3A;
                        border-radius: 12px;
                        .scheme__plan-item {
                            width: 315px;
                            padding: 20px;
                            border-bottom: 1px solid #2A2B3A;
                            display: flex;
                            justify-content: start;
                            align-items: center;
                            &:last-child {
                                border-bottom: none;
                            }
                            .scheme__plan-img {
                                width: 32px;
                                height: 32px;
                                margin-right: 20px;
                            }
                            .scheme__plan-name {
                                color: #FFFFFF;
                            }
                        }
                    }
                }
            }
        }
    }
}
    .scheme__triangles, .scheme__triangles-plan {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: -90px;
        right: 0;
        .scheme__triangle {
            width: 0;
            height: 0;
            border-radius: 10px;
        }
        .scheme__triangle-mini {
            border-left: 17px solid transparent;
            border-right: 17px solid transparent;
            border-top: 18px solid #171D29;
            margin-bottom: 10px;
        }
        .scheme__triangle-mid {
            border-left: 19px solid transparent;
            border-right: 19px solid transparent;
            border-top: 19px solid #2A2B3A;
            margin-bottom: 7px;
        }
        .scheme__triangle-big {
            border-left: 23px solid transparent;
            border-right: 23px solid transparent;
            border-top: 24px solid #4D4F6C;
        }
    }
    .scheme__triangles-plan {
        right: 100%;
        transform: translateX(100%);
    }

    @media only screen and (max-width: 1520px) {
        .scheme {
            overflow: hidden;
            .scheme__content {
                .scheme__list {
                    .scheme__row-icons {
                        .scheme__row-example {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 1060px) {
        .scheme {
            padding: 0 20px;
            margin-bottom: 83px;
            .scheme__title {
                margin-bottom: 64px;
            }
            .scheme__content {
                .scheme__list {
                    margin-right: 59px;
                    .scheme__row-icons {
                        margin-right: 59px;
                        width: 65px;
                    }
                    .scheme__row-cards {
                        .scheme__row-card {
                            width: 258px;
                            .scheme__row-title {
                                font-size: 24px;
                                line-height: 36px;
                            }
                            .scheme__row-text {
                                font-size: 16px;
                                line-height: 135%;
                                letter-spacing: -0.01em;
                            }
                        }
                        .scheme__row-card + .scheme__row-card {
                            margin-top: 70px;
                        }
                    }
                }
                .scheme__ilustration {
                    .scheme__ilustration-content {
                        width: 511px;
                        height: 560px;
                        transform: translateY(-129px);
                    }
                }
            }
            .scheme__content {
                display: flex;
            }
        }
    }

    @media only screen and (max-width: 768px) {
        .scheme {
            margin-bottom: 60px;
            .scheme__title {
                max-width: 767px;
            }
            .scheme__content {
                max-width: 767px;
                .scheme__list {
                    .scheme__row-icons {
                        margin-right: 35px;
                    }
                    margin-right: 37px;
                }
            }
        }
    }

    @media only screen and (max-width: 500px) {
        .scheme {
            margin: 0 auto;
            margin-bottom: 50px;
            overflow-x: initial;
            .scheme__title {
                margin-bottom: 31px;
            }
            .scheme__content {
                grid-template-columns: repeat(1, 1fr);
                .scheme__list {
                    margin-right: 0px;
                    .scheme__row-icons {
                        margin-right: 24px;
                        width: 52px;
                    }
                    .scheme__row-example {
                        display: none;
                    }
                    .scheme__row-cards {
                        margin-right: 0;
                        .scheme__row-card + .scheme__row-card {
                            margin-top: 30px;
                        }
                        .scheme__row-card {
                            width: 100%;
                            .scheme__row-title {
                                font-size: 24px;
                                line-height: 36px;
                                margin-bottom: 20px;
                            }
                            .scheme__row-text {
                                font-size: 16px;
                                line-height: 135%;
                            }
                        }
                    }
                }
                .scheme__ilustration {
                    display: none;
                }
            }
        }
            .scheme__triangles, .scheme__triangles-plan {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                position: absolute;
                top: -90px;
                right: 0;
                .scheme__triangle {
                    width: 0;
                    height: 0;
                    border-radius: 10px;
                }
                .scheme__triangle-mini {
                    border-left: 17px solid transparent;
                    border-right: 17px solid transparent;
                    border-top: 18px solid #171D29;
                    margin-bottom: 10px;
                }
                .scheme__triangle-mid {
                    border-left: 19px solid transparent;
                    border-right: 19px solid transparent;
                    border-top: 19px solid #2A2B3A;
                    margin-bottom: 7px;
                }
                .scheme__triangle-big {
                    border-left: 23px solid transparent;
                    border-right: 23px solid transparent;
                    border-top: 24px solid #4D4F6C;
                }
            }
            .scheme__triangles-plan {
                right: 100%;
                transform: translateX(100%);
            }
    }